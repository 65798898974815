<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Memoirs
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Add memoir"
              @click="$router.push({name: 'admin.memoir.create'})"
            ><i data-feather="plus" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card card--table">
              <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                  <h4><strong>{{ dataTotal }}</strong> memoirs</h4>
                </div>
                <div>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Open filters"
                  ><a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasFilters"
                    aria-controls="offcanvasFilters"
                    @click="openFilters()"
                  ><i data-feather="filter" /></a></span>
                  <!-- <a
                    href="#"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Export to Excel"
                  ><i data-feather="file" /></a> -->
                </div>
              </div>
              <div
                id="usedFilters"
                class="card-body"
              >
                Applied Filters:
                <span
                  v-for="filter, index in filters"
                  :key="index"
                  class="badge badge-light-dark ms-1"
                >
                  <template v-if="Array.isArray(filter)">
                    <template
                      v-for="str in filter"
                    >
                      {{ str.name }}
                      <a
                        :key="str.name"
                        class="ms-1 text-black"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete filter"
                        @click="deleteFilter(index)"
                      ><i data-feather="x" /></a>
                    </template>
                  </template>
                  <template v-else-if="typeof filter === 'object' && filter">
                    {{ filter.name }}
                    <a
                      class="ms-1 text-black"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete filter"
                      @click="deleteFilter(index)"
                    ><i data-feather="x" /></a>
                  </template>
                  <template v-else-if="filter">
                    {{ filter }}
                    <a
                      class="ms-1 text-black"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete filter"
                      @click="deleteFilter(index)"
                    ><i data-feather="x" /></a>
                  </template>
                </span>
              </div>

            </div>

            <div class="row">
              <div
                v-for="(memoir, index) in data"
                :key="index"
                class="col-sm-4 col-lg-3"
              >
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">
                      {{ memoir.title }}
                    </h4>
                    <h6 class="card-subtitle text-muted">
                      {{ memoir.year }}<span
                        v-if="memoir.status.status != 'Public'"
                        :class="`float-end badge bg-light-${memoir.status ? memoir.status.badge_class : 'secondary'} ms-1`"
                      >{{ memoir.status ? memoir.status.status : 'Draft' }}</span> <span :class="`float-end badge bg-light-${memoir.file ? 'success' : 'danger'}`">File: {{ memoir.file ? 'Yes' : 'No' }}</span>
                    </h6>
                    <div
                      v-if="memoir.image"
                      class="content__portada"
                    >
                      <img
                        class="object-fit-cover img-fluid"
                        :src="memoir.image.url"
                        :alt="memoir.alt_text"
                      >
                    </div>

                    <p class="card-text">
                      {{ memoir.icreas }} icreas
                    </p>
                    <div class="d-flex justify-content-between">
                      <a
                        class="card-link text-warning bg-light-warning btn btn-icon rounded-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Edit Memoir"
                        @click="$router.push({name: 'admin.memoir.edit', params: { id: memoir.id }})"
                      ><i data-feather="edit-3" /></a>
                      <a
                        v-if="!memoir.opened_icreas || !memoir.opened_promotion"
                        class="card-link text-info bg-light-info btn btn-icon rounded-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Open Memoir"
                        @click="openMemoir(memoir)"
                      ><i data-feather="book-open" /></a>
                      <a
                        class="card-link text-danger bg-light-danger btn btn-icon rounded-circle"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete Memoir"
                        @click="showDeleteModal(memoir.id)"
                      ><i data-feather="trash-2" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConvoFilter
      :filter-path="'memoirs/filter'"
    >
      <template #filters>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Title</label>
          <input
            type="text"
            class="form-control"
            placeholder=""
            @change="saveFilter($event.target.value, 'title')"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Year</label>
          <date-picker
            v-model="filters['year']"
            format="Y"
            type="year"
            value-type="format"
            class="w-100"
            @change="saveFilter($event, 'year')"
          />
        </div>
        <!-- <div class="mb-1">
          <label
            for=""
            class="form-label"
          >File</label>
          <select
            id=""
            name=""
            class="form-select"
            @input="saveFilter($event.target.value, 'file')"
          >
            <option value="">
              All
            </option>
            <option value="true">
              Yes
            </option>
            <option value="false">
              No
            </option>
          </select>
        </div> -->
      </template>
    </ConvoFilter>
    <OpenMemoir
      v-if="selectedMemoir.id"
      :memoir="selectedMemoir"
    />
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import ConvoFilter from '../../partials/offcanvas/ConvoFilter.vue'
import OpenMemoir from './components/OpenMemoir.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    ConvoFilter,
    DatePicker,
    OpenMemoir,
  },
  data() {
    return {
      selectedMemoir: {},
    }
  },
  computed: {
    ...mapGetters({
      filters: 'filters/filters',
      data: 'memoirs/items',
      dataTotal: 'memoirs/itemsTotal',
      loggedUser: 'auth/admin',
      filtersAux: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('memoirs/filter', this.filtersAux)
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'memoirs/fetch', url: `${Vue.prototype.$groupUrl}/memoirs/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    openFilters() {
      this.$store.dispatch('modals/toggleConvoFilters', true)
    },
    openMemoir(memoir) {
      this.selectedMemoir = memoir
      this.$store.dispatch('modals/toggleOpenMemoir', true)
    },
    async deleteFilter(index) {
      const { filters } = this
      this.$store.dispatch('filters/deleteFilters', index)

      this.$store.dispatch('filters/saveFilters', this.filters)
      await this.$store.dispatch('memoirs/filter', this.filters)

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 250)
    },
  },
}
</script>
