<template>
  <div>
    <div
      id="offcanvasFilters"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex w-auto"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasResearchLineLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="plus" /></span> Open memoir - {{ memoir.title }}
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body offcanvas-body--view">
        <div class="row">
          <div class="col-6">
            <strong>Promotion open date:</strong>
          </div>
          <div class="col-3">
            <strong>From:</strong> {{ memoir.promotion_begin_date }}
          </div>
          <div class="col-3">
            <strong>To:</strong> {{ memoir.promotion_end_date }}
          </div>
          <div class="col-6">
            <strong>Open date:</strong>
          </div>
          <div class="col-3">
            <strong>From:</strong> {{ memoir.begin_date }}
          </div>
          <div class="col-3">
            <strong>To:</strong> {{ memoir.end_date }}
          </div>
        </div>
        <hr>
        <div class="mb-1">
          <label
            for="nameseniorcall"
            class="form-label"
          >Mail template</label>
          <v-select
            v-model="mailTemplate"
            label="title"
            :options="templates"
            :get-option-key="option => option.id"
          />
        </div>
        <hr>
        <template v-if="!memoir.opened_promotion">
          <p>ICREAs under promotion ({{ promo.length }})</p>
          <table class="table">
            <thead>
              <tr>
                <th>Not.</th>
                <th @click="getIcreas('researcher_start_date')">
                  Begin date
                  <span :class="{'d-none': defaultField !== 'researcher_start_date'}">
                    <span
                      class="asc"
                      :class="{'d-none': order == 'DESC'}"
                    >
                      <i
                        data-feather="chevron-up"
                      />
                    </span>
                    <span
                      class="desc"
                      :class="{'d-none': order == 'ASC'}"
                    >
                      <i data-feather="chevron-down" />
                    </span>
                  </span>
                </th>
                <th @click="getIcreas('last_name')">
                  Name
                  <span :class="{'d-none': defaultField !== 'last_name'}">
                    <span
                      class="asc"
                      :class="{'d-none': order == 'DESC'}"
                    >
                      <i
                        data-feather="chevron-up"
                      />
                    </span>
                    <span
                      class="desc"
                      :class="{'d-none': order == 'ASC'}"
                    >
                      <i data-feather="chevron-down" />
                    </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="receiver in promo"
                :key="receiver.id"
              >
                <td>
                  <input
                    v-model="receiver.send"
                    type="checkbox"
                    value="1"
                  >
                </td>
                <td>{{ receiver.researcher_start_date }}</td>
                <td>{{ receiver.true_name }}</td>
              </tr>
            </tbody>
          </table>
          <hr>
        </template>
        <template v-else>
          <p><i
            data-feather="check"
            class="text-success me-1"
          />Promotion already opened</p>
        </template>
        <template v-if="!memoir.opened_icreas">
          <p>ICREAs ({{ icreas.length }})</p>
          <table class="table">
            <thead>
              <tr>
                <th>Not.</th>
                <th @click="getIcreas('researcher_start_date')">
                  Begin date
                  <span :class="{'d-none': defaultField !== 'researcher_start_date'}">
                    <span
                      class="asc"
                      :class="{'d-none': order == 'DESC'}"
                    >
                      <i
                        data-feather="chevron-up"
                      />
                    </span>
                    <span
                      class="desc"
                      :class="{'d-none': order == 'ASC'}"
                    >
                      <i data-feather="chevron-down" />
                    </span>
                  </span>
                </th>
                <th @click="getIcreas('last_name')">
                  Name
                  <span :class="{'d-none': defaultField !== 'last_name'}">
                    <span
                      class="asc"
                      :class="{'d-none': order == 'DESC'}"
                    >
                      <i
                        data-feather="chevron-up"
                      />
                    </span>
                    <span
                      class="desc"
                      :class="{'d-none': order == 'ASC'}"
                    >
                      <i data-feather="chevron-down" />
                    </span>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="receiver in icreas"
                :key="receiver.id"
              >
                <td>
                  <input
                    v-model="receiver.send"
                    type="checkbox"
                    value="1"
                  >
                </td>
                <td>{{ receiver.researcher_start_date }}</td>
                <td>{{ receiver.true_name }}</td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-else>
          <p><i
            data-feather="check"
            class="text-success me-1"
          />ICREAs already opened</p>
        </template>
      </div>
      <div class="offcanvas-footer mt-auto">
        <button
          type="button"
          class="btn btn-success mb-1 d-grid w-100"
          @click="openMemoir"
        >
          Open memoir and send notifications to ICREAs selected ({{ senders.length }})
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    memoir: { type: Object, required: true },
  },
  data() {
    return {
      promo: [],
      icreas: [],
      mailTemplate: null,
      order: 'ASC',
      defaultField: 'last_name',
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/openMemoir',
      templates: 'mailTemplates/items',
    }),
    senders() {
      return this.icreas.concat(this.promo).filter(e => e.send)
    },
  },
  watch: {
    memoir() {
      this.getIcreas()
    },
  },
  async mounted() {
    this.$store.dispatch('mailTemplates/filter', {
      name: 'memoir',
    })

    this.getIcreas()
  },
  methods: {
    async getIcreas(orderBy = null) {
      const icreas = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/memoir/${this.memoir.id}/get-icreas-resume${orderBy ? `?order=${orderBy}&dir=${this.order}` : ''}`)
      if (!this.memoir.opened_promotion) {
        this.promo = icreas.data.promo.map(e => {
          e.send = true
          return e
        })
      }
      if (!this.memoir.opened_icreas) {
        this.icreas = icreas.data.icreas.map(e => {
          e.send = true
          return e
        })
      }

      this.order = this.order === 'ASC' ? 'DESC' : 'ASC'
      this.defaultField = orderBy ?? 'last_name'
    },
    closeOffcanva() {
      this.$store.dispatch('modals/toggleOpenMemoir', false)
    },
    openMemoir() {
      Vue.swal({
        title: 'Do you want to open the memoir?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(async result => {
        if (result.isConfirmed) {
          this.$store.dispatch('memoirs/openMemoir', {
            memoir: this.memoir.id,
            senders: this.senders,
            mailTemplate: this.mailTemplate,
          }).then(() => {
            Vue.swal('Memoir opened', 'ICREA selected has received an email/notification to let them know', 'success')
            this.closeOffcanva()
          })
        }
      })
    },
  },
}
</script>
